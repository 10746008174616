<template>
    <div>
        <div class="notification">
            <div class="has-text-centered is-size-3">{{ roundData.coefficient }}</div>
        </div>
        <b-table :data="tickets">
            <template slot-scope="props">
                <b-table-column field="player" label="Player">
                    {{ props.row.player_id }}
                </b-table-column>

                <b-table-column field="betid" label="Bet #" centered>
                    {{ props.row.bet_id }}
                </b-table-column>

                <b-table-column field="bet" label="Bet" centered>
                    {{ fromCents(props.row.bet) }}
                </b-table-column>

                <b-table-column field="win" label="Win" centered>
                    {{ fromCents(props.row.win) }}
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import {fromCents} from "@/helpers/balanceConverter";

export default {
    props: {
        roundData: {
            type: Object,
            required: true,
            default() {
                return {
                    coefficient: undefined
                }
            }
        },
        tickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    bet: undefined,
                    win: undefined,
                    total: undefined,
                    player_id: undefined,
                }]
            }
        }
    },
    name: "FlightsModal",
    methods: {
        fromCents
    }
}
</script>

<style scoped>

</style>
